@import "../components//templates/stats";

.properties-content {
  padding: 30px;

  @media (max-width: 991px) {
    padding: 20px;
  }

  .properties {
    .properties-heading {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .add-property-btn {
        display: flex;
        align-items: center;
        background-color: #3856dd;
        border-radius: 50px;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        padding: 10px 15px;
        margin-bottom: 0;
        text-decoration: none;
        cursor: pointer;

        img {
          margin-right: 5px;
        }

        &:hover {
          filter: brightness(1.2);

        }
      }
    }

    .properties-boxes {
      .placeholder-box {
        flex-direction: column;
        margin-bottom: 20px;

        .placeholder-text-main {
          font-size: 16px;
          font-family: sans-serif;
          font-weight: 550;
          color: #6F6F6F;

        }
      }


      .box {
        border-radius: 20px;
        border: 1px solid #ebebeb;
        background-color: #fff;
        padding: 20px;
        margin-bottom: 15px;

        .icon2 {
          display: flex;
          align-items: center;
          border-radius: 50px;
          padding: 5px 10px;
          font-size: 14px;
          font-weight: 400;
          width: 180px;
          margin-bottom: 20px;

          img {
            margin-right: 5px;
          }

          p {
            margin-bottom: 0;
          }
        }

        .gtDisabled {
          background-color: gray;
          color: white;
        }

        .gtEnabled {
          background-color: green;
          color: white;
        }


        .dropdown-menu.dropdown-menu-dark {
          position: absolute !important;
          margin-top: -20px;
          // left: -130px !important;
          border: 1px solid #ebebeb;
          background-color: #fff;
          border-radius: 10px;
          padding: 10px 10px;
          box-shadow: 0 4px 20px 0 rgba($color: #000000, $alpha: 0.1);

          .delete {
            color: #f44141;
            font-size: 16px;
            align-items: center;

            span {
              position: relative;
            }

            &:hover {
              background-color: rgba(244, 65, 65, 0.1);
              border-radius: 4px;
            }
          }

          .add-timelock {
            color: #1c1b1b;
            font-size: 16px;
            padding: 0;
            padding-left: 16px;
            padding-top: 5px;
            padding-bottom: 5px;

            span {
              position: relative;
            }

            &:hover {
              background-color: rgba(28, 27, 27, 0.1);
              border-radius: 4px;
            }
          }

          .edit-property {
            color: #1c1b1b;
            font-size: 16px;
            padding: 0;
            padding-left: 16px;
            padding-top: 5px;
            padding-bottom: 5px;

            span {
              position: relative;
            }

            &:hover {
              background-color: rgba(28, 27, 27, 0.1);
              border-radius: 4px;
            }
          }
        }

        .box-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          .icon {
            display: flex;
            align-items: center;
            border-radius: 50px;
            padding: 5px 10px;
            font-size: 14px;
            font-weight: 400;

            img {
              margin-right: 5px;
            }

            p {
              margin-bottom: 0;
            }
          }

          .House {
            background-color: #d8fdf2;
            color: #18b297;
          }

          .Apartment {
            color: #9b22d4;
            background-color: #f3ecf3;
          }

          .Condo {
            background-color: #edf2fc;
            color: #3856dd;
          }

          .gtDisabled {
            background-color: gray;
            color: white;
          }

          .gtEnabled {
            background-color: green;
            color: white;
          }

          .dots {
            cursor: pointer;
          }
        }

        .heading-6 {
          color: #1c1b1b;
          font-size: 18px;
          font-weight: 600;
          font-family: "Raleway", sans-serif;
        }

        .count {
          color: #6f6f6f;
          font-size: 14px;
        }
      }

      .box#enabled {
        border: 2px solid green;
      }

      .box#disabled {
        border: 1px solid gray;
      }
    }
  }
}