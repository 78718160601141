@import "../components/templates/stats";

.dropdown-menu.dropdown-menu-dark {
  margin-top: 20px;
  border: 1px solid #ebebeb;
  background-color: #fff;
  padding: 15px 30px;

  .dropdown-item {
    font-size: 14px;
    font-weight: 400;
  }

  .setting {
    color: #6f6f6f;
  }

  .logout {
    color: #f44141;
  }

  .dropdown-item:active {
    background-color: transparent !important;
  }

  .dropdown-item:focus-visible {
    outline-offset: 0 !important;
  }
}

.dropdown-toggle::after {
  display: none;
}

.dashboard-content {
  padding: 30px;

  .heading-4 {
    margin-bottom: 30px;
  }

  .membership-detail {
    .membership-detail-heading {
      margin-bottom: 20px;
    }
    .membership-detail-box {
      border: 1px solid #ebebeb;
      padding: 40px 30px;
      border-radius: 20px;
      background-color: #fff;

      .membership-detail-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 573px) {
          flex-direction: column;
          align-items: start;
        }

        .tier-detail {
          .member-since {
            font-size: 18px;
            color: #413e3b;
            margin-bottom: 0;
          }

          @media (max-width: 573px) {
            margin-bottom: 25px;
          }
        }

        .contact-no {
          color: #3856dd;
          font-size: 25px;
          font-weight: 600;
          margin-bottom: 0;
        }
      }
    }
  }
}
