.properties-stats {
  margin-bottom: 30px;

  .heading-5 {
    margin-bottom: 20px;
  }
  .stats {
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;

    @media (max-width: 991px) {
      margin-bottom: 15px;
    }

    margin-bottom: 15px;

    .stats-text {
      .text {
        color: #6f6f6f;
        font-size: 14px;
        font-weight: 400;
      }

      .count {
        color: #1c1b1b;
      }
    }

    .stats-image {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .heading-1 {
        @media (max-width: 767px) {
          font-size: 40px;
        }
      }
      .img {
        width: 62px;
        height: 62px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
  }

  .totalProperties {
    background-color: #e4ffb8;
  }

  .totalVisits {
    background-color: #bbeafe;
  }

  .phone {
    background-color: #ffc9e0;
  }
}
