.profile-section {
  padding: 30px 50px;

  @media (max-width: 767px) {
    padding: 30px 15px;
  }

  .profile-dropdown {
    margin-bottom: 30px;

    @media (max-width: 991px) {
      display: none !important;
    }
  }

  .edit-profile {
    .name {
      margin-bottom: 40px;
    }

    .right-aligned-input {
      padding-left: 70px;
      text-align: right;
    }
  }
}

.edit-profile {

  .boxes{
    max-width: 991px;
    margin: auto;
    @media (max-width:991px) {
      width: 100%;
    }
  }
  .name {
    margin-bottom: 40px;
  }

  .data {
    color: #000;
    background-color: #fff;
    border: 1px solid #ebebeb;
    font-size: 18px;
    font-weight: 600;
    padding: 15px 20px;
    border-radius: 15px;
    margin-bottom: 15px;
    height: 70px;

    p {
      margin-bottom: 0;
    }

    .email {
      color: #707078;
      font-size: 16px;
      font-weight: 400;
    }

    .password {
      color: #707078;
      font-size: 16px;
      font-weight: 400;

      img {
        margin-left: 10px;
      }
    }

    .input-icon {
      width: 35px !important;
      width: 20px;
      height: auto;
      pointer-events: none;
    }
  }
}
