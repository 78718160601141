.navbar {
  background-color: transparent;

  .navbar-brand {
    width: 163px;
  }

  .nav-link {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-right: 20px;

    @media (max-width: 991px) {
      margin-right: 0;
    }

    &.active {
      text-decoration: underline;
      color: white;
    }
  }

  @media (max-width: 991px) {
    .navbar-collapse {
      background-color: #ffffff;
      text-align: center;
      margin-top: 20px;
      border-radius: 30px;

      .navbar-nav {
        padding-top: 30px;

        a {
          color: black;
        }
      }

      .navbar-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 30px 20px 30px;

        .transparent-btn {
          color: black;

          &:hover {
            background-color: transparent;
          }
        }

        .outline-btn {
          margin-top: 15px;
          background-color: #3856dd;
          border: 1px solid #3856dd;

          &:hover {
            opacity: 0.8;
            color: white;
          }
        }

        .btn {
          width: 100%;
        }

        .btn-outline {
          margin-right: 0 !important;
          margin-bottom: 15px;
        }
      }
    }
  }

  .closeIcon {
    background-color: rgba(255, 255, 255, 0.14);
    border-radius: 50%;
    padding: 15px;
  }

  .menuIcon {
    padding: 15px;
  }
}

.custom-toggler {
  display: none;
}

@media (max-width: 991px) {
  .custom-toggler {
    display: block;
  }
}