.primary-graident-btn {
  padding: 15px 36px;
  background: linear-gradient(81.37deg, #3856dd -15.83%, #8ba0ff 92.58%);
  border-radius: 50px;
  font-size: 18px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  border: none;
  font-weight: 700;
  &:hover {
    opacity: 0.8;
  }
}

.primary-btn {
  padding: 15px 36px;
  background-color: #3856dd;
  border-radius: 50px;
  font-size: 18px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  border: none;
  font-weight: 700;
  width: 100%;
  &:hover {
    opacity: 0.8;
  }
}

.primary-btn2 {
  padding: 15px 36px;
  background-color: #07092A;
  border-radius: 50px;
  font-size: 18px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  border: none;
  font-weight: 700;
  width: 100%;
  &:hover {
    opacity: 0.8;
  }
}


.danger-btn { 
  padding: 15px 36px;
  background-color: red;
  border-radius: 50px;
  font-size: 18px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  border: none;
  font-weight: 700;
  width: 100%;

  &:hover {
    opacity: 0.8;
    background-color: red;
  }
}

.secondary-btn {
  background-color: #06092a;
  padding: 15px 36px;
  border-radius: 50px;
  font-size: 18px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  border: none;
  font-weight: 700;
  &:hover {
    background-color: #06092a;
    opacity: 0.8;
  }

  &:active {
    background-color: #06092a !important;
    border-color: transparent !important;
  }

  &:focus-visible {
    box-shadow: none;
    border-color: transparent;
    background-color: #06092a;
  }
}

.transparent-btn {
  background-color: transparent;
  border: none;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  &:hover {
    background-color: transparent;
    //opacity: 0.8;
  }
}

.outline-btn {
  border: 1px solid #fff;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  border-radius: 50px;
  padding: 10px 30px;
  &:hover {
    border: 1px solid #fff;
    background-color: white;
    //opacity: 0.1;
    color: black;
  }
}
