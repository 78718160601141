.hero-section {
    background-color: #3856dd;
    padding-bottom: 380px;
    position: relative;
    color: #fff;

    min-width: 375px;

    @media (max-width: 991px) {
        padding-bottom: 190px;
    }

    .hero-heading {
        margin: 40px auto;

        @media (max-width: 991px) {
            margin: 20px auto;
        }
    }

    .heading-1 {
        max-width: 788px;
        width: 100%;

        @media (max-width: 767px) {
            font-size: 35px;
        }
    }

    .paragraph-medium {
        max-width: 599px;
        width: 100%;

        @media (max-width: 991px) {
            font-size: 18px;
        }
    }

    .contact {
        width: 100%;
        position: absolute;
    }

    .contact-box-c {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .contact-box {
        width: 600px;
        background-color: #f8f9ff;
        border-radius: 25px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding-bottom: 60px;

        @media (max-width: 630px) {
            width: 500px;
        }

        @media (max-width: 520px) {
            width: 350px;
        }

        .contact-row {
            padding-left: 50px;
            padding-right: 50px;
            margin-top: 10px;

            @media (max-width: 520px) {
                padding-left: 20px;
                padding-right: 20px;
            }

            .form-custom-label {
                display: flex;
                margin-top: 10px;
                justify-content: start;
                align-items: start;
                color: black;
                text-align: start;
                font-size: 22px;
                margin-left: 25px;

                @media (max-width: 520px) {
                    font-size: 20px;
                }

            }
        }

        .message-textarea {
            width: 100%;
            margin-top: 30px;

            &:focus-visible {
                outline: none;
            }

            .heading {
                font-size: 22px;
                font-weight: 600;
                font-family: 'Raleway', sans-serif;
                color: #000;
                margin-bottom: 15px;
                text-align: start;
                margin-left: 75px;

                @media (max-width: 520px) {
                    margin-left: 43px;
                    font-size: 20px;
                }

            }

            .custom-subtext {
                color: gray;
                text-align: start;
                font-size: 14px;
                font-style: italic;

                margin-left: 75px;

                @media (max-width: 520px) {
                    margin-left: 43px;
                    font-size: 14px;
                }
            }

            .textarea {
                width: 500px;
                color: #000;
                resize: none;
                background-color: #fff;
                border: 1px solid lightgray;
                border-radius: 25px;
                height: 165px;
                padding: 20px;
                text-align: start;
                font-size: 18px;

                &::placeholder {
                    color: #B8B8B8;
                }

                &:focus-visible {
                    outline: #B8B8B8;
                }

                @media (max-width: 630px) {
                    width: 400px;
                }

                @media (max-width: 520px) {
                    width: 300px;
                }

                &:disabled {
                    cursor: not-allowed;
                }
            }
        }

        .submit-btn {
            display: flex;
            justify-content: center;
            margin-top: 30px;

            @media (max-width: 0px) {
                justify-content: center;

                div {
                    width: 100%;
                }
            }

            .resp-txt {
                width: 325px;
            }
        }

        .checkbox {
            display: flex;
            align-items: flex-start;
            position: relative;
            color: #000;
            margin-left: 40px;
            margin-top: 40px;
            width: 500px;

            @media (max-width: 630px) {
                width: 450px;
            }

            @media (max-width: 520px) {
                margin-left: 20px;
                margin-top: 40px;
            }

            .signup-text {
                font-size: 14px;

                @media (max-width: 520px) {
                    width: 280px;
                    font-size: 12px;
                }
            }

            .custom-checkbox-container {
                margin-top: 3px;

                .hidden-checkbox {
                    position: absolute;
                    opacity: 0;
                    pointer-events: none;
                }

                .custom-checkbox {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background-color: #fff;
                    border: 1px solid lightgray;
                    border-radius: 2px;
                    cursor: pointer;
                    position: relative;
                    transition: background-color 0.3s, border-color 0.3s;
                    margin-right: 12px;
                }

                .custom-checkbox.checked {
                    background-color: #3856dd;
                    border-color: #3856dd;
                }

                .custom-checkbox .checkmark {
                    position: absolute !important;
                    top: -5px;
                    left: 2px;
                    font-size: 16px;
                    color: white;
                }
            }
        }

        .checkbox#last-checkbox {
            margin-top: 10px;
        }

    }

    .shape {
        position: absolute;
    }

    .shape-1 {
        left: -14%;
        top: -75%;

        @media (max-width: 1400px) {
            left: -24%;
        }

        @media (max-width: 1200px) {
            left: -38%;
        }

        @media (max-width: 991px) {
            // display: none;
        }
    }
}

.hero-section#quote {
    height: 1000px;
}