.payment-success {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 15px;

    .logo {
        margin-bottom: 35px;
    }

    .payment-success-box {
        border: 1px solid #EBEBEB;
        padding: 50px 30px 40px;
        border-radius: 30px;
        background-color: #fff;
        max-width: 500px;
        text-align: center;

        @media (max-width: 573px) {
            padding: 50px 20px 30px;
        }

        .check-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 25px;
        }

        .heading-3 {
            margin-bottom: 15px;

            @media (max-width: 573px) {
                font-size: 30px;
            }
        }

        .paragraph-main {
            margin-bottom: 15px;
            color: #6F6F6F;
        }
    }
}

.paragraph-type {
    font-weight: bold;
    margin-left: 20px;
    font-family: "Raleway", sans-serif;
}

.paragraph-val {
    font-size: 19px;
    font-weight: 400;
}

.pay-confirm {
}