
.subscription-create-modal {
    display: flex !important;
    justify-content: center;
    align-items: center;

    .modal-dialog {
        width: 600px;
        display: flex;

        @media (max-width: 573px) {
            width: 320px;
        }
    }
    .close-btn {
        cursor: pointer;
    }

    .modal-content {
        padding: 20px;
        border-radius: 30px;
        border: none;
        text-align: center;
        width: 600px;
        padding-bottom: 50px;

        @media (max-width: 767px) {
            padding-bottom: 30px;
        }


        @media (max-width: 573px) {
            width: 320px;
        }

        .modal-heading {
            font-size: 35px;
            font-weight: 600;
            font-family: 'Raleway', sans-serif;
        }

        .modal-text {
            color: #6F6F6F;
            font-size: 18px;

            span {
                font-weight: 700;
            }
        }

        .pricing-btn {
            background-color: #0d6efd;
            font-size: 18px;
            font-weight: 700;
            font-family: 'Raleway', sans-serif;
            border: none;
            border-radius: 50px;
            padding: 12px 90px;

            @media (max-width: 573px) {
                padding: 12px 40px;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
}
