.pricing-section {
  background: linear-gradient(180deg,
      rgba(56, 86, 221, 0.1) 0%,
      rgba(139, 160, 255, 0.1) 100%);
  padding: 100px;
  border-radius: 50px;
  color: #000;

  @media (max-width: 767px) {
    border-radius: 0;
    padding: 60px 10px;
    padding-bottom: 55px;
    padding-top: 55px;
  }

  .heading {
    .heading-1 {
      margin-bottom: 80px;

      @media (max-width: 1260px) {
        margin-bottom: 30px;
        font-size: 35px;
      }
    }
  }

  .heading-4 {
    @media (max-width: 767px) {
      font-size: 30px;
    }
  }

  .paragraph-small {
    @media (max-width: 991px) {
      font-size: 19px;
    }

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .paragraph-main {
    color: #413e3b;
  }

  .middle {
    margin-top: -30px;

    @media (max-width: 991px) {
      margin-top: 0;
    }
  }

  .pricing-box {
    border: 1px solid #ebebeb;
    height: 790px;
    background-color: #fff;
    padding: 40px;
    border-radius: 20px;

    @media (max-width: 767px) {
      padding: 30px;
    }

    .check {
      width: 25px;
      height: 25px;
      background-color: rgba(14, 158, 89, 0.1);
      border-radius: 50%;
      flex: 0 0 25px;
    }

    .paragraph-small {
      color: #4e4e4e;
    }

    .price {
      .heading-1 {
        color: #292a36;
        margin-bottom: 20px;

        @media (max-width: 1265px) {
          font-size: 35px;
        }

        @media (max-width: 991px) {
          font-size: 50px;
        }
      }

      button {
        width: 100%;
        border-radius: 50px;
        color: #fff;
        padding: 15px 30px;
        border: none;
      }

      .pricing-btn {
        background-color: #3856dd;

        &:hover {
          opacity: 0.8;
        }
      }

      .pricing-btn-light {
        color: #000;
        background-color: #ebeeff;

        &:hover {
          opacity: 0.8;
        }
      }

      .price-desc {
        margin-left: 10px;
        margin-top: 10px;
      }

      .pricing-subfooter {
        color: #4e4e4e;
        font-style: italic;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }

  .pricing-footer {
    color: #4e4e4e;
    font-style: italic;
    font-size: 14px;
  }

  .pricing-footer-href {
    text-decoration: none !important;
  }
}