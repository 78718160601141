.sidebar-overlay{
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.dashboard-sidebar {
  width: 260px;

  @media (max-width: 1200px) {
    width: 205px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
  .sidebar {
    background-color: #fff;
    border: 1px solid #edeaea;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 260px;
    padding: 30px 15px;
    z-index: 1050;
    transition: transform 0.3s ease;
    @media (max-width: 991px) {
      transform: translateX(-300px);
    }

    @media (max-width: 1200px) {
      width: 205px;
    }

    @media (max-width: 991px) {
      width: 280px;
    }
  }

  .show {
    transform: translateX(0) !important;
    transition: all 0.3s ease-in;
  }

  .sidebar-logo {
    margin-bottom: 40px;

    @media (max-width: 991px) {
      margin-bottom: 0;
    }
    @media (max-width: 767px) {
      // margin-bottom: 20px;
    }
  }

  .sidebar-close-button{
    display: none;
    @media (max-width: 991px) {
      display: block;
      // margin-bottom: 20px;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    color: #1c1b1b;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    padding: 10px 30px;
  }

  .nav-link.active {
    background-color: #3856dd;
    margin-left: 0;
    border-radius: 50px;
    color: #fff;

    img {
      color: #fff;
    }
  }
}
