@import "../components/templates/signup-login-common";
.login-box {

    overflow-y: hidden;
    overflow-x: hidden;

    height: 100%;
    min-height: 100%;
    background-color: #F8F9FF;

    .signup-login-common {
        margin: 0;
        padding: 50px;

        @media (max-width: 573px) {
            padding: 40px 10px;
        }
    }


}

