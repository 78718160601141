.seamless-section {
  color: #000;
  margin-bottom: 60px;
  padding-top: 100px;
  margin-top: 5px;

  @media (max-width: 767px) {
    padding-top: 60px;
  }

  .seamless-box {
    padding: 40px;
    border-radius: 50px;
    box-shadow: 0px 4px 100px 0px #0000000d;
    border: 1px solid #f2f2f2;

    .serial {
      opacity: 8%;
    }

    @media (max-width: 767px) {
      border-radius: 27px;
    }
  }

  .heading-1 {
    @media (max-width: 767px) {
      font-size: 30px;
    }
  }

  .paragraph-main {
    color: #6f6f6f;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}
