.back-btn {
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    display: flex;
    align-items: center;
    padding: 0;
    &:active {
      background-color: transparent;
    }

    &:hover {
        background-color: transparent;
        color: #000;
    }

    &:focus-visible {
        box-shadow: none;
        background-color: transparent;
    }

    &:active {
        background-color: transparent !important;
    }

    &:active:focus-visible,
    &:not(.btn-check) + &:active:focus-visible {
      box-shadow: none;
      background-color: transparent;
      border-color: transparent;
      color: #000;
    }

    .back-icon {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ebebeb;
      background-color: white;
    }
  }