.footer-section {
  background-color: #060520;
  padding: 60px 20px;

  @media (max-width: 767px) {
      padding: 60px 20px 30px;
  }

  .social-links .social {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgb(255, 255, 255, 0.1);
  }

  .footer-link {
    color: #bcbcbc;
    font-size: 14px;
    text-decoration: none;
  }

  .paragraph-main {
    color: #bcbcbc;

    @media (max-width: 991px) {
      font-size: 18px;
    }
  }

  .heading-5 {
    color: #fff;

    font-size: 18px;

    @media (max-width: 991px) {
      font-size: 18px;
    }
  }

  .mobile-social-links {
    display: none !important;
    a:hover{
      opacity: 0.8;
    }
  }

  .desktop-social-links {
    a:hover{
      opacity: 0.8;
    }
  }

  @media (max-width: 991px) {
    .desktop-social-links {
      display: none !important;
    }

    .mobile-social-links {
      display: flex !important;
    }
  }
}
