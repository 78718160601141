.contact-us {
    padding: 30px;

    .heading-3 {
        @media (max-width: 767px) {
            font-size: 30px;
        }
    }

    @media (max-width: 573px) {
        padding: 15px;
    }
    
    .contact-us-content {
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    .paragraph-main{
        color: #6F6F6F;
    }

    .contact-detail {
        background: linear-gradient(rgba(56, 86, 221, 0.1) 0%, rgba(139, 160, 255, 0.1) 100%);
        padding: 30px 30px;
        border-radius: 30px;
        max-width: 337px;
        margin-right: 20px;

        @media (max-width: 767px) {
            margin-bottom: 20px;
            max-width: 100%;
            margin-right: 0;
            padding-bottom: 20px;
        }

        .heading {
            margin-bottom: 20px;

            @media (max-width: 767px) {
                text-align: center;
            }
        }

        .email {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            @media (max-width: 767px) {
                justify-content: center;
                margin-bottom: 15px;
            }

            .icon {
                width: 58px;
                height: 58px;
                background-color: #000;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 58px;
                margin-right: 15px;
                border-radius: 50%;
            }

            .text {
                .heading-6 {
                    margin-bottom: 0;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }

        .contact-img {
            text-align: center;

            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    .message {
        background-color: #fff;
        border-radius: 30px;
        padding: 40px;
        max-width: 780px;
        flex: 1;

        @media (max-width: 767px) {
            padding: 40px 20px;
            text-align: center;
        }
        
        .message-textarea {
            width: 100%;
            margin-top: 30px;

            &:focus-visible {
                outline: none;
            }
            .heading {
                font-size: 20px;
                font-weight: 600;
                font-family: 'Raleway', sans-serif;
                color: #000;
                margin-bottom: 15px;
                text-align: start;
            }

            .textarea {
                width: 100%;
                resize: none;
                border-radius: 15px;
                background-color: #F8F9FF;
                border: none;
                height: 165px;
                padding: 15px;
                font-size: 18px;
                font-weight: 400;

                &::placeholder {
                    color: #B8B8B8;
                }

                &:focus-visible {
                    outline: none;
                }
            }
        }

        .submit-btn {
            display: flex;
            justify-content: end;
            margin-top: 20px;

            @media (max-width: 573px) {
                justify-content: center;

                div {
                    width: 100%;
                }
            }
        }
    }
}