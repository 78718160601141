.custom-input {
  .form-label {
    font-size: 18px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
  }

  .custom-subtext {
    color: gray;
    text-align: start;
    margin-left: 25px;
    font-size: 14px;
    font-style: italic;
    margin-top: 0px;
  }

  .form-control {
    border: 1px solid lightgray;
    background-color: #fff;
    border-radius: 50px;
    padding: 16px 30px;
    color: #000;
    font-size: 18px;

    &::placeholder {
      color: #a39f9f;
    }

    &:focus-visible {
      outline: none;
    }

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      background-color: #f5f5f5;
      color: #a1a1a1;
      cursor: not-allowed;
      border: 1px solid #dcdcdc;
    }
  }
}