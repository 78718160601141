@import "../components/templates/signup-login-common";

.error-text {
  font-size: 12px;
  color: red;
}

.signup-box {
  .signup-content {
    @media (max-width: 991px) {
      .back-btn {
        position: static;
        top: 10px;
        background-color: #f8f9ff !important;
      }
      .logo {
        margin-top: 70px;
      }
    }
  }

  @media (max-width: 991px) {
    .signup-login-common {
      height: 100%;
      min-height: 100vh;
      padding-bottom: 60px;
    }
  }

  .checkbox {
    display: flex;
    align-items: flex-start;
    position: relative;

    .signup-text{
      font-size: 14px;
    }

    .custom-checkbox-container {
      margin-top: 3px;

      .hidden-checkbox {
        position: absolute;
        opacity: 0;
        pointer-events: none;
      }

      .custom-checkbox {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #e1e1e1;
        border-radius: 2px;
        cursor: pointer;
        position: relative;
        transition: background-color 0.3s, border-color 0.3s;
        margin-right: 12px;
      }

      .custom-checkbox.checked {
        background-color: #3856dd;
        border-color: #3856dd;
      }

      .custom-checkbox .checkmark {
        position: absolute !important;
        top: -5px;
        left: 2px;
        font-size: 16px;
        color: white;
      }
    }
  }
}
