.add-timelock-main {
    padding: 30px;
    min-width: 350px;
    
    @media (max-width: 573px) {
      padding: 15px;
    }
  
    .heading-5 {
      margin-bottom: 15px;
    }

    .signup-text#entry-dates-sub {
        @media (max-width: 573px) {
            margin-bottom: 15px;
        }

        margin-bottom: 25px;
    }

    .signup-text#time-sub {
        @media (max-width: 573px) {
            margin-bottom: 15px;
        }
        
        margin-bottom: 10px;
    }
  
    .add-timelock-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      
      .add-timelock-content {
        background-color: #fff;
        border-radius: 30px;
        padding: 40px;
        width: 500px;
        border: 1px solid #ebebeb;
  
        @media (max-width: 573px) {
          width: 100%;
          padding: 40px 20px;
        }
      }
  
      .heading-6 {
        margin-bottom: 15px;

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
      }

      .heading-6#timelock-title {
        margin-bottom: 20px;
        font-weight: 650;
        font-size: 28px;
      }

      .heading-6#timelock-subtitle {
        margin-bottom: 15px;
        font-weight: 650;
        font-size: 22px;
      }

      .heading-6#timelock-time-label {
        margin-bottom: 5px;
        margin-top: 10px;
        font-weight: 600;
        font-size: 18px;
      }
  
      .checkbox-inputs {
        display: flex;
        margin-bottom: 30px;

        @media (max-width: 767px) {
            margin-bottom: 0px;
        }
      }

      .checkbox-inputs#last-checkbox {
        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
      }

      .checkbox-inputs#all-day-checkbox {
        display: flex;
        margin-bottom: 30px;
        margin-left: 5px;

        @media (max-width: 767px) {
            margin-bottom: 20px;
            margin-top: 5px;
        }

        @media (max-width: 575px) {
            margin-bottom: 20px;
            margin-top: 40px;
        }
      }
  
      .checkbox-label {
        margin-right: 45px;
        font-size: 16px;
        font-weight: 400;
        color: #000;
        display: flex;
        align-items: center;
        width: 100%;
  
        @media (max-width: 767px) {
          margin-bottom: 15px;
        }
      }

      .toggle-switch {
        position: relative;
        width: 50px;
        height: 25px;
        display: inline-block;
        margin-right: 5px;
      }
      
      .toggle-switch-checkbox {
        display: none;
      }

      .toggle-switch-label {
        display: block;
        width: 100%;
        height: 100%;
        background-color: gray;
        border-radius: 25px;
        transition: background-color 0.3s ease-in-out;
        position: relative;
      }
      
      .toggle-switch-label::after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        
        background-color: lightgray;
        border-radius: 50%;
        position: absolute;
        top: 2.5px;
        left: 2.5px;
        transition: transform 0.3s ease-in-out;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      }

      .toggle-switch-checkbox:checked + .toggle-switch-label {
        // background-color: #1261cd;
        background-color: #ECEFFC;
      }
      
      .toggle-switch-checkbox:checked + .toggle-switch-label::after {
        transform: translateX(25px);
        background-color: #1261cd;
      }
  
      .input-checkbox {
        box-shadow: 0px 0px 0px 1px #ececec;
        width: 20px;
        height: 20px;
  
        border: 1px solid #dccece;
        background-clip: border-box;
        border-radius: 5px;
        appearance: none;
        transition: border 0.3s, box-shadow 0.3s;
        margin-right: 8px;
        cursor: pointer;

        &:checked {
            border-color: #1261cd;
            background-color: #1261cd;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14'%3E%3Cpath fill='white' d='M20.285 5.28a1 1 0 0 1 0 1.414l-10 10a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414l4.293 4.293 9.293-9.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
        }
      }

      .input-checkbox:checked {
        border: none;
      }

      .time-selector {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #000;
        flex-direction: row;
      
        .time-input {
          width: 100%;
          padding: 12px;
          background-color: white;
          border: 1px solid #ececec;
          box-shadow: 0px 0px 0px 1px #ececec;
          border-radius: 25px;
          text-align: start;
          appearance: none; // hides default styling
          font-size: inherit;
          color: inherit;
          font-size: 15px;
      
          &:focus {
            outline: none;
            border-color: #3856dd;
          }

          &:disabled {
            background-color: #ECEFFC;
            color: #a1a1a1;
            cursor: not-allowed;
            border: 1px solid #ECEFFC;
          }
        }
      
        .time-separator {
          font-size: 16px;
          font-weight: bold;
          margin: 0 10px;
        }
      }  
    }

    .create-prp {
      background: linear-gradient(81.37deg, #3856dd -15.83%, #8ba0ff 92.58%);
    }

    .del-prp {
      background-color: #f6e7e6 !important;
      color: red;
    }
  }
  