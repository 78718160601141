.add-property {
  padding: 30px;
  min-width: 330px;

  @media (max-width: 573px) {
    padding: 15px;
  }

  .heading-5 {
    margin-bottom: 15px;
  }

  .add-property-box {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    .add-property-content {
      background-color: #fff;
      border-radius: 30px;
      padding: 40px;
      width: 500px;
      border: 1px solid #ebebeb;

      @media (max-width: 573px) {
        width: 100%;
        padding: 40px 20px;
      }
    }

    .create-prp {
      background: linear-gradient(81.37deg, #3856dd -15.83%, #8ba0ff 92.58%);
    }

    .del-prp {
      background-color: #f6e7e6 !important;
      color: red;
    }

    .heading-6 {
      margin-bottom: 15px;
    }

    .radio-inputs {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: center;
    }

    .radio-label {
      margin-right: 45px;
      font-size: 16px;
      font-weight: 400;
      color: #000;
      display: flex;
      align-items: center;
      width: 100%;

      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
    }

    .input-radio {
      box-shadow: 0px 0px 0px 1px #ececec;
      width: 20px;
      height: 20px;

      border: 4px solid #fff;
      background-clip: border-box;
      border-radius: 50%;
      appearance: none;
      transition: border 0.3s, box-shadow 0.3s;
      margin-right: 8px;
    }

    .input-radio:checked {
      //   box-shadow: 0px 0px 0px 4px #3856dd;
      border: 4px solid #3856dd;
    }
  }
}
