* {
  box-sizing: border-box;
}
body {
  overflow-x: hidden !important;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0 !important;
}

.dashboard-body {
  height: 100%;
  background-color: #f8f9ff;
  overflow-x: hidden;
  padding: 0;
}

#root {
  height: 100%;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
}

.heading-1 {
  font-size: 50px;
  font-family: "Raleway", sans-serif;

  &-semi-bold {
    font-weight: 600;
  }
}

.heading-2 {
  font-size: 40px;
  font-family: "Raleway", sans-serif;

  &-semi-bold {
    font-weight: 600;
  }
}

.heading-5 {
  font-size: 25px;
  font-family: "Raleway", sans-serif;

  &-semi-bold {
    font-weight: 600;
  }

  &-bold {
    font-weight: 700;
  }
}

.heading-3 {
  font-size: 35px;
  font-family: "Raleway", sans-serif;

  &-semi-bold {
    font-weight: 600;
  }
}

.heading-4 {
  font-size: 30px;
  font-family: "Raleway", sans-serif;

  &-semi-bold {
    font-weight: 600;
  }
}

.heading-6 {
  font-size: 18px;
  font-family: "Raleway", sans-serif;

  &-semi-bold {
    font-weight: 600;
  }
}

.paragraph-medium {
  font-size: 25px;
  font-family: "Inter", sans-serif;

  &-regular {
    font-weight: 400;
  }
}

.paragraph-main {
  font-size: 18px;
  font-family: "Inter", sans-serif;

  &-regular {
    font-weight: 400;
  }
}

.paragraph-small {
  font-size: 16px;
  font-family: "Inter", sans-serif;

  &-regular {
    font-weight: 400;
  }
}

.heading-6 {
  font-size: 18px;
  font-family: "Raleway", sans-serif;

  &-bold {
    font-weight: 700;
  }
}

.asterisk {
  color: red;
}
