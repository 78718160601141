.change-password {
  padding: 30px 50px;

  @media (max-width: 767px) {
    padding: 30px 15px;
  }

  .profile-dropdown {
    margin-bottom: 50px;
  }

  .profile-dropdown {
    margin-bottom: 50px;

    @media (max-width: 991px) {
      display: none !important;
    }
  }

  .back-btn {
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    display: flex;
    align-items: center;
    padding: 0;
    &:active {
      background-color: transparent;
    }

    &:active:focus-visible,
    &:not(.btn-check) + &:active:focus-visible {
      box-shadow: none;
      background-color: transparent;
      border-color: transparent;
      color: #000;
    }

    .back-icon {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ebebeb;
      background-color: white;
    }
  }

  .form {
    max-width: 500px;
    border: 1px solid #ebebeb;
    border-radius: 32px;
    background-color: #fff;
    padding: 40px 30px 30px;
    margin: 10px auto;

    @media (max-width: 991px) {
      padding: 20px;
    }

    .form-label {
      font-size: 18px;
      font-weight: 600;
      font-family: "Raleway", sans-serif;
    }

    .form-control {
      border: 1px solid #ececec;
      background-color: #fff;
      border-radius: 50px;
      padding: 16px 30px;
      color: #000;
      font-size: 18px;

      &::placeholder {
        color: #a39f9f;
      }

      &:focus-visible {
        outline: none;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}
