
    .subscription-cancel-modal {
        display: flex !important;
        justify-content: center;
        align-items: center;

        

        .modal-dialog {
            width: 600px;
            display: flex;

            @media (max-width: 573px) {
                width: 320px;
            }
        }
        .close-btn {
            cursor: pointer;
        }

        .modal-content {
            padding: 20px;
            border-radius: 30px;
            border: none;
            text-align: center;
            width: 600px;
            padding-bottom: 50px;

            @media (max-width: 767px) {
                padding-bottom: 30px;
            }


            @media (max-width: 573px) {
                width: 320px;
            }

            .modal-heading {
                font-size: 35px;
                font-weight: 600;
                font-family: 'Raleway', sans-serif;
            }

            .modal-text {
                color: #6F6F6F;
                font-size: 18px;

                span {
                    font-weight: 700;
                }
            }

            .model-text-warning {
                color: black;
                font-size: 13px;
                font-weight: 700;
            }

            .pricing-btn {
                background-color: #f6e7e6;
                color: red;
                font-size: 18px;
                font-weight: 700;
                font-family: 'Raleway', sans-serif;
                border: none;
                border-radius: 50px;
                padding: 12px 90px;
    
                @media (max-width: 573px) {
                    padding: 12px 40px;
                }
            }
    
            .pricing-btn:hover {
                background-color: red;
                color: #f6e7e6;
            }
        }
    }
