.signup-login-common {
  margin: 20px;

  @media (max-width: 991px) {
    background-color: #f8f9ff;
    margin: 0;
  }

  .signup-content {
    background-color: #f8f9ff;
    padding: 40px 20px;
    border-radius: 50px;

    @media (max-width: 991px) {
      padding: 10px;
      border-radius: 0;
    }

    .heading-2 {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .img-1 {
    @media (max-width: 991px) {
      display: none;
    }
  }
  .back-btn {
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    display: flex;
    align-items: center;
    &:active {
      background-color: transparent;
    }

    &:active:focus-visible,
    &:not(.btn-check) + &:active:focus-visible {
      box-shadow: none;
      background-color: transparent;
      border-color: transparent;
      color: #000;
    }

    .back-icon {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ebebeb;
      background-color: white;
    }
  }

  .form {
    max-width: 430px;

    .form-control {
      border: 1px solid #ececec;
      background-color: #fff;
      border-radius: 50px;
      padding: 16px 30px;
      color: #000;
      font-size: 18px;

      &::placeholder {
        color: #a39f9f;
      }

      &:focus-visible {
        outline: none;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .paragraph-small {
      color: #595959;
      font-weight: 500;
    }

    .paragraph-main {
      font-weight: 500;
    }

    .paragraph-sub {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .link,
  .forget-password-link {
    &:hover {
      opacity: 0.8;
    }
  }
}
