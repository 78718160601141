.hero-section {
  background-color: #3856dd;
  padding-bottom: 380px;
  position: relative;
  color: #fff;

  @media (max-width: 991px) {
    padding-bottom: 190px;
  }

  .hero-heading {
    margin: 40px auto;

    @media (max-width: 991px) {
      margin: 20px auto;
    }
  }

  .heading-1 {
    max-width: 788px;
    width: 100%;

    @media (max-width: 767px) {
      font-size: 35px;
    }
  }

  .paragraph-medium {
    max-width: 599px;
    width: 100%;

    @media (max-width: 991px) {
      font-size: 18px;
    }
  }

  .hero-img-1 {
    margin: 30px auto;

    @media (max-width: 991px) {
      display: none;
    }
  }

  .hero-img-1-mobile {
    display: none;
    margin: 30px auto;

    @media (max-width: 991px) {
      display: block;
      position: absolute;
      margin: 10px auto;
      width: 100%;
      bottom: -300px;
    }
  }

  .hero-img-2 {
    width: 100%;
    position: absolute;
    //top: 60%;

    img {
      box-shadow: 0px 15px 100px 0px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }

  .shape {
    position: absolute;
  }

  .shape-1 {
    left: -14%;
    top: -75%;

    @media (max-width: 1400px) {
      left: -24%;
    }

    @media (max-width: 1200px) {
      left: -38%;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }
}
