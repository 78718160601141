.desktop-text-version{
  display: none;
    @media (min-width: 768px) {
      display: block;
    }
}

.mobile-text-version{
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}