.subscription-verification-common {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // height: 100%;
  padding: 15px;

  .logo {
    margin-bottom: 35px;
  }

  .success-box {
    max-width: 500px;
  }

  .verification-box {
    max-width: 725px;
  }

  .success-verification-box {
    border: 1px solid #ebebeb;
    padding: 50px 35px 40px;
    border-radius: 30px;
    background-color: #fff;
    text-align: center;

    @media (max-width: 573px) {
      padding: 50px 20px 30px;
    }

    .check-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 25px;
    }

    .heading-3 {
      margin-bottom: 15px;

      @media (max-width: 573px) {
        font-size: 30px;
      }
    }

    .paragraph-main {
      margin-bottom: 15px;
      color: #6f6f6f;
    }

    .verification-btn {
      text-align: center;

      .btn {
        width: auto;
        padding: 15px 65px;

        @media (max-width: 573px) {
          padding: 14px 48px;
        }
      }
    }
  }
}

.email-verification {
  .mobile-navbar {
    justify-content: end;
    .btn-primary {
      display: none !important;
    }

    .mobile-navabr-logo {
      display: none !important;
    }
  }

  .subscription-verification-common {
    justify-content: start;
    margin-top: 100px;
  }
}
