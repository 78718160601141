.cta-section {
  .container {
    @media (max-width: 573px) {
      padding: 0;
    }
  }
  .cta {
    background: linear-gradient(81.37deg, #3856dd -15.83%, #8ba0ff 92.58%);
    overflow: hidden;
    border-radius: 50px;
    margin: 60px auto;
    padding: 30px 0 60px 40px;

    @media (max-width: 573px) {
      margin: 0 auto;
      border-radius: 0;
    }

    @media (max-width: 573px) {
      padding: 20px;
      padding-bottom: 60px;
    }

    .cta-text {
      color: #fff;

      @media (max-width: 991px) {
        padding-right: 40px;
      }

      @media (max-width: 573px) {
        padding-right: 20px;
      }

      .cta-heading {
        font-size: 48px;
        font-family: "Raleway", sans-serif;
        font-weight: 700;

        @media (max-width: 767px) {
          font-size: 35px;
        }
      }

      .paragraph-medium {
        @media (max-width: 767px) {
          font-size: 18px;
        }
      }
    }

    .cta-img {

      height: 363px;

      @media (max-width: 991px) {
        height: 100%;
      }
      img {
        position: relative;
        //top: 85px;


        @media (max-width: 1200px) {
          top: 0;
        }

        @media (max-width: 991px) {
          margin-bottom: 40px;
          width: 750px;
        }

        @media (max-width: 767px) {
          width: 600px;
        }

        @media (max-width: 500px) {
          width: 500px;
        }
      }
    }
  }
}
