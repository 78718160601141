.subscription-section {
  color: #000;
  padding: 30px 0;

  min-width: 360px;

  @media (max-width: 767px) {
    border-radius: 0;
    padding: 30px 10px;
  }

  .heading {
    .heading-1 {
      margin-bottom: 40px;
      font-size: 40px;

      @media (max-width: 1260px) {
        margin-bottom: 30px;
        font-size: 35px;
      }

      @media (max-width: 990px) {
        margin-bottom: 30px;
        font-size: 35px;
      }
    }
  }

  .heading-4 {
    @media (max-width: 767px) {
      font-size: 30px;
    }
  }

  .paragraph-small {
    @media (max-width: 767px) {
      font-size: 19px;
    }
  }

  .paragraph-main {
    color: #413e3b;
  }

  .profile-dropdown {
    margin-bottom: 30px;
    padding-right: 50px;

    @media (max-width: 991px) {
      display: none !important;
    }
  }

  .subscription-plan-boxes {
    width: 100%;
    padding: 0 30px;

    @media (max-width: 991px) {
      width: auto;
      padding: 0 10px;
    }

    .pricing-box {
      border: 1px solid #ebebeb;
      height: 800px;
      background-color: #fff;
      padding: 40px 20px;
      border-radius: 20px;
      position: relative;

      .desktop-text{
        display: none;
        @media (min-width: 767px) {
          display: block;
        }
      }
      .mobile-text{
        display: none;
        @media (max-width: 767px) {
          display: block;
        }
      }

      @media (max-width: 767px) {
        padding: 30px;
      }

      .check {
        width: 25px;
        height: 25px;
        background-color: rgba(14, 158, 89, 0.1);
        border-radius: 50%;
        flex: 0 0 25px;
      }

      .paragraph-small {
        color: #4e4e4e;
      }

      .price {
        .heading-1 {
          color: #292a36;
          margin-bottom: 20px;

          @media (max-width: 1260px) {
            margin-bottom: 30px;
            font-size: 40px;
          }
    
          @media (max-width: 767px) {
            margin-bottom: 30px;
            font-size: 50px;
          }
        }

        button {
          width: 100%;
          border-radius: 50px;
          color: #fff;
          padding: 15px 10px;
          border: none;

          &:hover{
            opacity: 0.8;
          }
        }

        .pricing-btn {
          color: red;
          font-weight: 700;
          font-size: 18px;
          background-color: #f6e7e6;
          &:hover {
            background-color: red;
            color: #f6e7e6;
          }
        }

        .pricing-btn-light {
          color: #000;
          background-color: #ebeeff;
          &:hover {
            opacity: 0.8;
          }
        }

        .price-desc {
          margin-left: 10px;
          margin-top: 10px;
        }
      }
    }

    .current-plan {
      font-size: 18px;
      font-weight: 500;
      position: absolute;
      background-color: #18b27b;
      width: 140px;
      height: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -0.3%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      border-radius: 50px;
    }
  }

  .pricing-footer {
    color: #4e4e4e;
    font-style: italic;
    font-size: 14px;
  }

  .pricing-footer-href {
    text-decoration: none !important;
  }
}
