.features-section {
  a{
    text-decoration: none;
  }

  color: #000;
  margin-top: 250px;
  padding-top: 100px;

  .heading-1 {
    @media (max-width: 767px) {
      font-size: 30px;
    }
  }

  @media (max-width: 991px) {
    margin-top: 80px;
    padding-top: 60px;
  }

  .paragraph-medium {
    color: #6f6f6f;

    @media (max-width: 1200px) {
      font-size: 18px;
    }
  }

  .heading-2 {
    @media (max-width: 1200px) {
      font-size: 30px;
    }

    @media (max-width: 767px) {
      font-size: 25px;
    }
  }

  .points{
    list-style-type: none;
    padding:0;
    li{

      display: flex;
      align-items: flex-start;
      @media (max-width: 991px) {
        text-align: left;
      }
      .heading{
        font-family: "Raleway", sans-serif;
        font-weight: 600;
        font-size: 25px;

        @media (max-width: 767px) {
          font-size: 20px;
        }
      }

      .check{
        position: relative;
        top:-2px;
      }

      .info{
        margin-left: 12px;
        p{
          color: #6f6f6f;
        }
      }
    }
  }
}