.mobile-navbar {
  min-width: 350px;
  display: flex;
  padding: 30px;
  button {
    background-color: transparent;
    border: none;
    padding: 0;
    &:hover,&:active{
      background-color: transparent !important;
    }
  }

  .dropdown-toggle {
    padding: 0;
    margin-bottom: 0;
  }

  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown-menu.dropdown-menu-dark {
    margin-top: 45px;
  }

  .profile-dropdown{
    //padding: 30px 50px;
    .dropdown-menu{
      border-radius: 10px;
      padding:12px !important;
      border: 1px solid #EBEBEB;
      transform: none !important;
      .dropdown-item{
        font-size: 16px !important;
      }
    }
  }
}

